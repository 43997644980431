import { FaPlay } from "react-icons/fa";
import { useRef, useState, useCallback, useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  useNodesState,
  useEdgesState,
} from "reactflow";
import { FaRegTrashAlt } from "react-icons/fa";
import { LiaBroomSolid } from "react-icons/lia";
import { IoClose, IoChatbox } from "react-icons/io5";
import { TextField, ThemeProvider, createTheme } from "@mui/material";
import "reactflow/dist/style.css";
import Input from "./nodes/Input";
import DatabaseConnection from "./nodes/DatabaseConnection";
import Agent from "./nodes/Agent";
import Output from "./nodes/Output";
import Prompt from "./nodes/Prompt";
import TextArea from "./nodes/TextArea";
import SqlQuery from "./nodes/SqlQuery";
import IfElse from "./nodes/IfElse";
import SqlQueryGenerator from "./nodes/SqlQueryGenerator";
import QubridLLM from "./nodes/LLm Models/QubridLLM";
import NvidiaNimLLM from "./nodes/LLm Models/NvidiaNimLLM";
import HuggingFaceLLM from "./nodes/LLm Models/HuggingFaceLLM";
import { NodeDataProvider } from "./NodeDataContext";
import qubridLogo from "../../assets/agentslogos/qubridL.svg";

const nodeTypes = {
  inputNode: Input,
  databaseConnectionNode: DatabaseConnection,
  agentNode: Agent,
  outputNode: Output,
  promptNode: Prompt,
  textAreaNode: TextArea,
  sqlQueryNode: SqlQuery,
  ifElseNode: IfElse,
  sqlQueryGeneratorNode: SqlQueryGenerator,
  qubridLLMNode: QubridLLM,
  nvidiaNimLLMNode: NvidiaNimLLM,
  huggingfaceLLMNode: HuggingFaceLLM,
};

const defaultEdgeOptions = {
  style: {
    strokeWidth: 1,
    stroke: "#8F27C1",
    strokeDasharray: "5 5",
  },
  animated: true,
  type: "smoothstep",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#8F27C1",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "6px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E5E7EB",
            },
            "&:hover fieldset": {
              borderColor: "#E5E7EB",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#9333EA",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#374151",
          "&.Mui-focused": {
            color: "#374151",
          },
        },
      },
    },
  },
});

const PipelineUI = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isDraggingOverTrash, setIsDraggingOverTrash] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [isFlowRunning, setIsFlowRunning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const savedFlow = localStorage.getItem("agentic-workbench-flow");
    if (savedFlow) {
      const flow = JSON.parse(savedFlow);
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
    }
  }, [setNodes, setEdges]);

  useEffect(() => {
    if (nodes.length || edges.length) {
      const flow = {
        nodes,
        edges,
        viewport: reactFlowInstance?.getViewport(),
      };
      localStorage.setItem("agentic-workbench-flow", JSON.stringify(flow));
    }
  }, [nodes, edges, reactFlowInstance]);

  const clearFlow = useCallback(() => {
    setNodes([]);
    setEdges([]);
    localStorage.removeItem("agentic-workbench-flow");
  }, [setNodes, setEdges]);

  const onConnect = useCallback(
    (params) => {
      const edge = {
        ...params,
        type: "smoothstep",
        style: defaultEdgeOptions.style,
        animated: defaultEdgeOptions.animated,
        deletable: true,
      };
      setEdges((eds) => addEdge(edge, eds));
    },
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onInit = useCallback((instance) => {
    setReactFlowInstance(instance);

    const savedFlow = localStorage.getItem("agentic-workbench-flow");
    if (savedFlow) {
      const flow = JSON.parse(savedFlow);
      if (flow.viewport) {
        instance.setViewport(flow.viewport);
      }
    }
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: `${type}-${nodes.length + 1}`,
        type,
        position,
        data: { label: `${type} node` },
        sourcePosition: "right",
        targetPosition: "left",
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, nodes, setNodes]
  );

  const onNodeDragStart = useCallback((event, node) => {}, []);

  const onNodeDrag = useCallback((event, node) => {
    const nodeElement = event.target.getBoundingClientRect();
    const trashZone = document.getElementById("trash-zone");

    if (trashZone) {
      const trashRect = trashZone.getBoundingClientRect();
      const isOverTrash =
        nodeElement.right >= trashRect.left &&
        nodeElement.left <= trashRect.right &&
        nodeElement.bottom >= trashRect.top &&
        nodeElement.top <= trashRect.bottom;
      setIsDraggingOverTrash(isOverTrash);
    }
  }, []);

  const onNodeDragStop = useCallback(
    (event, node) => {
      const nodeElement = event.target.getBoundingClientRect();
      const trashZone = document.getElementById("trash-zone");

      if (trashZone) {
        const trashRect = trashZone.getBoundingClientRect();
        const isOverTrash =
          nodeElement.right >= trashRect.left &&
          nodeElement.left <= trashRect.right &&
          nodeElement.bottom >= trashRect.top &&
          nodeElement.top <= trashRect.bottom;

        if (isOverTrash) {
          setNodes((nds) => nds.filter((n) => n.id !== node.id));
          setEdges((eds) =>
            eds.filter((e) => e.source !== node.id && e.target !== node.id)
          );
        }
      }
      setIsDraggingOverTrash(false);
    },
    [setNodes, setEdges]
  );

  const handleNodeDataChange = useCallback(
    (nodeId, data) => {
      const connectedEdges = edges.filter((edge) => edge.source === nodeId);

      connectedEdges.forEach((edge) => {
        const targetNode = nodes.find((node) => node.id === edge.target);
        if (targetNode) {
          setNodes((nds) =>
            nds.map((node) =>
              node.id === targetNode.id
                ? { ...node, data: { ...node.data, inputData: data } }
                : node
            )
          );
        }
      });
    },
    [edges, nodes, setNodes]
  );

  const getNextNodes = useCallback(
    (nodeId) => {
      const outgoingEdges = edges.filter((edge) => edge.source === nodeId);

      return outgoingEdges
        .map((edge) => nodes.find((node) => node.id === edge.target))
        .filter(Boolean);
    },
    [edges, nodes]
  );

  const getStartNodes = useCallback(() => {
    return nodes.filter(
      (node) => !edges.some((edge) => edge.target === node.id)
    );
  }, [nodes, edges]);

  const runNextNode = useCallback(() => {
    if (!isFlowRunning) return;

    if (currentNodeId) {
      const nextNodes = getNextNodes(currentNodeId);

      if (nextNodes.length > 0) {
        const nextNode = nextNodes[0];
        setCurrentNodeId(nextNode.id);
        setNodes((nds) =>
          nds.map((node) =>
            node.id === nextNode.id
              ? {
                  ...node,
                  style: { ...node.style, border: "2px solid #8F27C1" },
                }
              : node.id === currentNodeId
              ? { ...node, style: { ...node.style, border: "none" } }
              : node
          )
        );
      } else {
        const remainingNodes = nodes.filter(
          (node) =>
            !edges.some((edge) => edge.target === node.id) &&
            node.id !== currentNodeId &&
            !node.style?.border
        );

        if (remainingNodes.length > 0) {
          const nextStartNode = remainingNodes[0];
          setCurrentNodeId(nextStartNode.id);
          setNodes((nds) =>
            nds.map((node) =>
              node.id === nextStartNode.id
                ? {
                    ...node,
                    style: { ...node.style, border: "2px solid #8F27C1" },
                  }
                : node.id === currentNodeId
                ? { ...node, style: { ...node.style, border: "none" } }
                : node
            )
          );
        } else {
          setIsFlowRunning(false);
          setCurrentNodeId(null);
          setNodes((nds) =>
            nds.map((node) => ({
              ...node,
              style: { ...node.style, border: "none" },
            }))
          );
          setIsChatOpen(true);
          setMessages((msgs) => [
            ...msgs,
            { text: "Flow execution completed.", sender: "assistant" },
          ]);
        }
      }
    }
  }, [isFlowRunning, currentNodeId, nodes, edges, getNextNodes, setNodes]);

  const startFlow = useCallback(() => {
    if (nodes.length === 0) return;

    setIsFlowRunning(true);
    const startNodes = getStartNodes();
    if (startNodes.length > 0) {
      const firstNode = startNodes[0];
      setCurrentNodeId(firstNode.id);
      setNodes((nds) =>
        nds.map((node) =>
          node.id === firstNode.id
            ? { ...node, style: { ...node.style, border: "2px solid #8F27C1" } }
            : node
        )
      );
    }
  }, [nodes, setNodes, getStartNodes]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;
  
    const newMessages = [...messages, { text: inputMessage, sender: "user" }];
    setMessages(newMessages);
    setInputMessage("");
    setIsLoading(true);
  
    try {
      const response = await fetch("https://dev.platform.qubrid.com/generate_sql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: inputMessage }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const data = await response.json();
  
      // Format SQL response
      const sqlResponse = `Your SQL query to get the answer is: \`\`\`sql\n${data.generated_query}\n\`\`\``;
  
      // Handle the second response (text, image, or table)
      let formattedResponse;
      if (typeof data.response === "string") {
        // Clean the response string (remove truncation or invalid characters)
        const cleanedResponse = data.response.replace(/…/g, "").trim();
  
        try {
          // Parse the cleaned JSON string into an array of objects
          const parsedResponse = JSON.parse(cleanedResponse);
  
          // Check if the parsed response is an array
          if (Array.isArray(parsedResponse)) {
            // Convert array to table
            formattedResponse = (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr>
                      {Object.keys(parsedResponse[0]).map((key) => (
                        <th
                          key={key}
                          className="px-4 py-2 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {parsedResponse.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, idx) => (
                          <td
                            key={idx}
                            className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  onClick={() => {
                    const csvContent =
                      "data:text/csv;charset=utf-8," +
                      parsedResponse
                        .map((row) => Object.values(row).join(","))
                        .join("\n");
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "data.csv");
                    document.body.appendChild(link);
                    link.click();
                  }}
                  className="mt-2 px-2 py-1 bg-[#F3E8FF] text-[#9333EA] rounded-md hover:bg-[#E9D5FF] transition-colors font-medium"
                >
                  Download CSV
                </button>
              </div>
            );
          } else {
            formattedResponse = "Response is not in a valid tabular format.";
          }
        } catch (error) {
          if ( data.response.startsWith("http")) {
                    formattedResponse = (
                      <div>
                        <img
                          src={ data.response}
                          alt="Response"
                          className="max-w-full h-auto rounded-lg"
                        />
                        <a
                          href={ data.response}
                          download
                          className="mt-2 px-2 py-1 bg-[#F3E8FF] text-[#9333EA] rounded-md hover:bg-[#E9D5FF] transition-colors font-medium"
                        >
                          Download Image
                        </a>
                      </div>
                    );
                  } else {
                    // Check if it's a bullet list
                    if ( data.response.includes("\n")) {
                      formattedResponse = (
                        <ul className="list-disc pl-5">
                          { data.response.split("\n").map((line, index) => (
                            <li key={index}>{line}</li>
                          ))}
                        </ul>
                      );
                    } else {
                      // Simple text
                      formattedResponse =  data.response;
                    }
                  }
          // console.error("Error parsing response:", error);
          // formattedResponse = "Failed to parse the response.";
        }
      } else if (Array.isArray(data.response)) {
        // If the response is already an array, convert it to a table
        formattedResponse = (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  {Object.keys(data.response[0]).map((key) => (
                    <th
                      key={key}
                      className="px-4 py-2 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.response.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, idx) => (
                      <td
                        key={idx}
                        className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700"
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => {
                const csvContent =
                  "data:text/csv;charset=utf-8," +
                  data.response
                    .map((row) => Object.values(row).join(","))
                    .join("\n");
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "data.csv");
                document.body.appendChild(link);
                link.click();
              }}
              className="mt-2 px-2 py-1 bg-[#F3E8FF] text-[#9333EA] rounded-md hover:bg-[#E9D5FF] transition-colors font-medium"
            >
              Download CSV
            </button>
          </div>
        );
      } else {
        // Handle other types of responses (e.g., JSON)
        formattedResponse = JSON.stringify(data.response, null, 2);
      }
  
      setMessages((msgs) => [
        ...msgs,
        { text: sqlResponse, sender: "assistant", isSQL: true },
        { text: formattedResponse, sender: "assistant" },
      ]);
    } catch (error) {
      setMessages((msgs) => [
        ...msgs,
        {
          text: "Failed to generate response. Please try with a different query.",
          sender: "assistant",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).catch((err) => {
        console.error("Failed to copy text: ", err);
      });
    } else {
      // Fallback for browsers that do not support navigator.clipboard
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <NodeDataProvider>
      <ThemeProvider theme={theme}>
        <div
          ref={reactFlowWrapper}
          className="w-full h-[calc(100vh-120px)] bg-[#fafafa] relative"
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            nodeTypes={nodeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            fitView={false}
          >
            <Background color="#e2e2e2" gap={20} variant="dots" size={4} />
            <div className="flex justify-between space-y-2">
              <button
                onClick={isFlowRunning ? runNextNode : startFlow}
                className="hover:bg-gray-100 absolute top-0 right-48 mt-2 z-50 bg-white rounded-sm px-2 py-1 text-[#8F27C1] flex items-center justify-center border border-[#8F27C1] text-sm"
                title="Run Pipeline"
              >
                <FaPlay size={12} className="mr-1" />
                Play
              </button>
              <Controls position="top-right" className="flex gap-2">
                <button
                  onClick={clearFlow}
                  className="hover:bg-gray-100 bg-white font-semibold rounded-sm px-2 py-1 text-sm"
                  title="Clear Flow"
                >
                  <LiaBroomSolid size={20} />
                </button>
              </Controls>
            </div>

            <div
              id="trash-zone"
              className={`absolute bottom-8 right-4 size-10 rounded-md flex items-center justify-center transition-all duration-200 z-50 ${
                isDraggingOverTrash
                  ? "bg-red-500 scale-110"
                  : "bg-white hover:bg-gray-300"
              }`}
            >
              <FaRegTrashAlt
                className={`size-5 ${
                  isDraggingOverTrash ? "text-white" : "text-gray-600"
                }`}
              />
            </div>
            <MiniMap position="bottom-center" ariaLabel="Agent Workflow MiniMap" />
          </ReactFlow>

          {/* Chat Toggle Button */}
          <button
            onClick={() => setIsChatOpen(!isChatOpen)}
            className="fixed right-4 bottom-4 z-50 bg-[#8F27C1] text-white rounded-full p-3 shadow-lg hover:bg-[#7B1FA2] transition-colors"
          >
            <IoChatbox size={24} />
          </button>

          {/* Chat Section */}
          <div
            className={`fixed right-0 top-24 h-[calc(100vh-200px)] w-[400px] md:w-[600px] lg:w-[800px] bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
              isChatOpen ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ zIndex: 1000 }}
          >
            {/* Header */}
            <div className="px-4 py-3 border-b flex justify-between items-center">
              <h2 className="text-lg font-semibold">Chat Section</h2>
              <button
                onClick={() => setIsChatOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <IoClose size={24} />
              </button>
            </div>

            {/* Content Area */}
            <div className="flex flex-col h-[calc(100vh-200px)]">
              <div className="flex-1 overflow-y-auto px-4 py-3">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 flex ${
                      message.sender === "user"
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    {message.sender === "assistant" && (
                      <img
                        src={qubridLogo || "/placeholder.svg"}
                        alt="Qubrid Logo"
                        className="size-6 mr-2 mb-2 self-end"
                      />
                    )}
                    <div
                      className={`max-w-[80%] p-3 rounded-lg ${
                        message.sender === "user"
                          ? "bg-purple-100 text-purple-900"
                          : "bg-gray-100 text-gray-900"
                      }`}
                    >
                      {message.isSQL ? (
                        <div>
                          <pre className="whitespace-pre-wrap break-words">
                            {message.text}
                          </pre>
                          <button
                            onClick={() => {
                              const sqlText = message.text.match(/```sql\n([\s\S]*?)\n```/)[1];
                              copyToClipboard(sqlText);
                            }}
                            className="mt-2 px-2 py-1 bg-[#F3E8FF] text-[#9333EA] rounded-md hover:bg-[#E9D5FF] transition-colors font-medium"
                          >
                            Copy SQL
                          </button>
                        </div>
                      ) : (
                        message.text
                      )}
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-start">
                    <div className="max-w-[80%] p-3 rounded-lg bg-gray-100 text-gray-900">
                      Generating response...
                    </div>
                  </div>
                )}
              </div>
              {/* Input */}
              <div className="border-t bg-white p-4">
                <div className="flex items-center gap-2">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your message..."
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                    size="small"
                  />
                  <button
                    className="px-4 py-2 bg-[#F3E8FF] text-[#9333EA] rounded-md hover:bg-[#E9D5FF] transition-colors font-medium"
                    onClick={handleSendMessage}
                    disabled={isLoading}
                  >
                    Run
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </NodeDataProvider>
  );
};

export default PipelineUI;