import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, Route, Routes } from "react-router-dom";
import ForgetPassword from "./component/Login/ForgetPass";
import EmailVerify from "./component/Login/EmailVerify";
import Login from "./component/Login/Login";
import SignUp from "./component/Login/SignUp";
import HomePage from "./pages/HomePage";
import AuthRoute from "./component/AuthRoute";
import { initializeApp } from "firebase/app";
import { config } from "./config/config";
import EnvironmentAndInfra from "./component/EnvironmentAndInfra/EnvironmentAndInfra";
import { Context } from "./Context";
import EnvironmentForm from "./component/EnvironmentForm/EnvironmentForm";
import NewPass from "./component/Login/NewPass";
import MemberPass from "./component/Login/MemberPass";
import PaymentSuccess from "./component/PaymentSuccess";
import PaymentFailed from "./component/PaymentFailed";
import MyInstance from "./component/MyInstance/MyInstance";
import HelpHome from "./component/Help/HelpHome";
import HelpInfo from "./component/Help/HelpInfo";
import Home from "./component/MyOrganization/Home";
import ApplicationHome from "./component/Applications/ApplicationHome";
import UploadFile from "./component/Applications/UploadFile";
import ApplicationResult from "./component/Applications/ApplicationResult";
import Jupyter from "./component/Jupyter";
import AiCompute from "./component/RunAIMlCpuGpu/AiCompute";
import Protected from "./component/Protected/Protected";
import CompanyVerification from "./component/MyOrganization/CompanyVerification";
import TryQuantum from "./component/QuantumInfra/QuantumInfra";
import ModelHome from "./component/Models/ModelHome";
import ModelCategory from "./component/Models/ModelCategory";
import ModelInfo from "./component/Models/ModelInfo";
import DataHistroy from "./component/Applications/DataHistroy";
import DataStorage from "../src/component/UserData/DataStorage";
import UserFolder from "./component/UserData/UserFolder";
import ViewDetails from "./component/DashboardActions/ViewDetails";
import ContainerLogs from "./component/DashboardActions/ContainerLogs";
import DeployModel from "./component/DashboardActions/DeployModel";
import GetCode from "./component/DashboardActions/GetCode";
import GetCodeDenvr from "./component/DashboardActions/GetCodeDenvr";
import GetUi from "./component/DashboardActions/GetUi";
import GetUiT from "./component/DashboardActions/GetUiT";
import GetRagUi from "./component/DashboardActions/GetRagUi";
import AgenticUI from "./component/AgenticWorkFlow/AgenticUI";
import LogViewer from './component/LogViewer';
initializeApp(config.firebaseConfig);

function App() {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [planid, setPlanId] = useState({});
  const [modalInfo, setModalInfo] = useState([]);
  const [freeShowModal, setFreeShowModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [updateCredit, setUpdateCredit] = useState(false);
  const [addCredits, setAddCredits] = useState(false);
  const [preditionRes, setPreditionRes] = useState(null);
  const [filesDataRes, setFileDataRes] = useState(null);
  const [adminInfo, setAdminInfo] = useState(null);
  const [aiModelFilter, setAiModelFilter] = useState(
    localStorage.getItem("aiModelFilter") || "All"
  );
  const [instanceUp, setInstanceUp] = useState("offline");
  const [modelStatusLight, setModelStatusLight] = useState();

  // const handleRefreshToken = async () => {
  //   try {
  //     await axiosInstance.post("/user/refresh_token");
  //   } catch (error) {
  //     console.log("refresh token");
  //   }
  // };
  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const response = await axiosInstance.get("/api/v1/get-user");
  //       setAdminInfo(response.data);
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
  //         const refreshed = await handleRefreshToken();
  //         if (refreshed) {
  //           getUser();
  //         }
  //       } else {
  //         console.error("Error:", error);
  //       }
  //     }
  //   };
  //   getUser();
  // }, []);

  return (
    
    <Context.Provider
      value={{
        open,
        addCredits,
        setAddCredits,
        setOpen,
        isLoading,
        setIsLoading,
        planid,
        setPlanId,
        modalInfo,
        setModalInfo,
        freeShowModal,
        setFreeShowModal,
        deleteSuccess,
        setDeleteSuccess,
        updateCredit,
        setUpdateCredit,
        preditionRes,
        setPreditionRes,
        filesDataRes,
        setFileDataRes,
        adminInfo,
        setAdminInfo,
        aiModelFilter,
        setAiModelFilter,
        instanceUp,
        setInstanceUp,
        modelStatusLight,
        setModelStatusLight,
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgetpass" element={<ForgetPassword />} />
        <Route path="/passwordVerification/:token" element={<NewPass />} />
        <Route path="/memberVerify/:token" element={<MemberPass />} />
        <Route path="/emailVerify/:token" element={<EmailVerify />} />
        <Route
        
          path="/companyVerify/:token"
          element={<Protected Component={CompanyVerification} />}
        />
        <Route
          path="/userData"
          element={<Protected Component={DataStorage} />}
        />
        <Route
          path="/userFolder"
          element={<Protected Component={UserFolder} />}
        />
        <Route
          path="/environment"
          element={<Protected Component={EnvironmentAndInfra} />}
        />

        <Route
          path="/createnewprogram"
          element={<Protected Component={EnvironmentForm} />}
        />
        <Route
          path="/paymentSuccess"
          element={<Protected Component={PaymentSuccess} />}
        />
        <Route
          path="/aiQuantumEngine"
          element={<Protected Component={TryQuantum} />}
        />
        <Route
          path="/paymentFailed"
          element={<Protected Component={PaymentFailed} />}
        />

        <Route
          path="/myInstance"
          element={<Protected Component={MyInstance} />}
        />

        <Route
          path="/myorganization"
          element={<Protected Component={Home} />}
        />
        <Route path="/help" element={<Protected Component={HelpHome} />} />
        <Route path="/helpInfo" element={<Protected Component={HelpInfo} />} />
        <Route
          path="/applications"
          element={<Protected Component={ApplicationHome} />}
        />
        <Route
          path="/uploadfiles"
          element={<Protected Component={UploadFile} />}
        />

        <Route
          path="/dataHistroy"
          element={<Protected Component={DataHistroy} />}
        />
        <Route
          path="/applicationResult"
          element={<Protected Component={ApplicationResult} />}
        />
        <Route path="/jupyter" element={<Protected Component={Jupyter} />} />
        <Route
          path="/aiCompute"
          element={<Protected Component={AiCompute} />}
        />

        {/* ROUTES FOR - AI MODEL PAGES */}
        <Route
          path="/modelStudio"
          element={<Protected Component={ModelHome} />}
        />
        <Route
          path={`/modelStudio/:category`}
          element={<Protected Component={ModelCategory} />}
        />
        <Route
          path={`/modelStudio/:category/:modelName`}
          element={<Protected Component={ModelInfo} />}
        />
        <Route
          path={`/modelStudio/:category/:modelName/*`}
          element={<Navigate to="/modelStudio" />}
        />

        <Route
          path="/view-detail"
          element={<Protected Component={ViewDetails} />}
        />
        <Route
          path="/container-logs"
          element={<Protected Component={ContainerLogs} />}
        />
        <Route
          path="/deploy"
          element={<Protected Component={DeployModel} />}
        />
        <Route
          path="/getCode"
          element={<Protected Component={GetCode} />}
        />
        <Route
          path="/getCodeUi"
          element={<Protected Component={GetCodeDenvr} />}
        />
        <Route
          path="/getUi"
          element={<Protected Component={GetUi} />}
        />
        <Route
          path="/getUiT"
          element={<Protected Component={GetUiT} />}
        />
         <Route
          path="/getRagUi"
          element={<Protected Component={GetRagUi} />}
        />
         <Route
          path="/agenticWorkbench"
          element={<Protected Component={AgenticUI} />}
        />
        <Route
          path="/logs"
          element={<Protected Component={LogViewer} />}
        />
      </Routes>
      <ToastContainer />
    </Context.Provider>
  );
}

export default App;
